(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    // RESOURCE CENTER
    // Convert links to iframed videos to open immediately on click. Instead of opening up the lightbox first
    if ($('.component.resource-center').length) {
      console.log('testing', $('.file-link').length);
      $(document).ajaxStop(function () {
        // last ajax call finished  needed to wait for this so that we can update the links retrived from the resource center ajax call.
        console.log('ajax finished', $('.file-link').length);
        $('.file-link.file-download-directly').each(function (index, elem) {
          console.log('elem, index', elem, index);
          var $elem = $(elem);
          var href = $elem.attr('href');
          if (href.indexOf('iframe') > -1) {
            $elem.attr('title', href);
          }
        });
        window.DGS.Event.getInstance().trigger('link-embed-code-detected'); // activate the video spot overlay
      });
    }
  });
})(jQuery);