/**
 * Created by mady on 04-07-2017.
 */
(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    DGS.Components.DataHierarchySpot.getInstance().register("api-documentation", function (data, $spot) {
      var entries = [],
        navigationContainer,
        navigation = [],
        apiEntryContainer,
        methods,
        $param,
        $paramName,
        $paramDescription,
        $example,
        entityName;
      if (data.hasOwnProperty('API Entry')) {
        data['API Entry'].forEach(function (apiEntry) {
          entityName = apiEntry.name.substr(apiEntry.name.lastIndexOf('.') + 1);
          navigation.push($('<a href="#' + entityName + '" class="class">' + entityName + '</a>'));
          methods = [];
          if (apiEntry.hasOwnProperty('API Method')) {
            apiEntry['API Method'].forEach(function (apiMethod) {
              //console.log(apiMethod);
              navigation.push($('<a href="#' + entityName + apiMethod.name + '" class="method ' + entityName + '">' + apiMethod.name + '</a>'));
              methods.push($('<a name="' + entityName + apiMethod.name + '"></a>'));
              methods.push($('<h4 class="name">' + apiMethod.name + '</h4>'));
              methods.push($('<p class="exec">' + apiMethod.exec + ' returns ' + apiMethod.returns + '</p>'));
              methods.push($('<p class="description">' + apiMethod.description + '</p>'));
              if (apiMethod.hasOwnProperty('API Param')) {
                methods.push($('<p class="param-label bg-color-2">Parameter</p>'));
                apiMethod['API Param'].forEach(function (apiParam) {
                  $param = $('<div class="param bg-color-3"></div>');
                  $paramName = $('<p class="param-name bg-color-3">' + apiParam.name + '</p>');
                  $paramDescription = $('<p class="param-description bg-color-4"></p>');
                  $paramDescription.html(apiParam.description);
                  $param.append($paramName);
                  $param.append($paramDescription);
                  $param.append($('<div style="clear:both;"></div>'));
                  methods.push($param);
                });
              }
              methods.push($('<p class="returns-label">Returns</p>'));
              methods.push($('<p class="returns">' + apiMethod.returns + '</p>'));
              if (apiMethod.hasOwnProperty('example')) {
                methods.push($('<p class="example-label">Example</p>'));
                $example = $('<p class="example bg-color-4"></p>');
                $example.html('<pre>' + apiMethod.example + '</pre>');
                methods.push($example);
              }
            });
          }
          apiEntryContainer = {
            className: 'api-entry-container',
            containers: [{
              className: 'title',
              components: [$('<a name="' + entityName + '"></a>'), $('<span>' + apiEntry.name + '</span>')]
            }, {
              className: 'description',
              components: [$('<span>' + apiEntry.description.replace(/\[/g, '<').replace(/\]/g, '>') + '</span>')]
            }, {
              className: 'section-title',
              components: [$('<span>Methods</span>')]
            }, {
              className: 'methods',
              components: methods
            }]
          };
          navigationContainer = {
            className: 'api-nav-container',
            components: navigation
          };
          entries.push(apiEntryContainer);
        });
        entries.push(navigationContainer);
      }
      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: entries
          }
        }
      };
    }, function () {
      var $apiDocContainer = $('.api-documentation'),
        $classLinks = $('a.class', $apiDocContainer),
        $methodLinks = $('a.method', $apiDocContainer);
      DGS.Scroller.getInstance().register(function (scrollTop) {
        var windowHeight = $(window).height();
        if (scrollTop > $apiDocContainer.offset().top - 30) {
          $('.api-nav-container', $apiDocContainer).addClass('sticky');
        } else {
          $('.api-nav-container', $apiDocContainer).removeClass('sticky');
        }
        $classLinks.each(function () {
          var $this = $(this),
            href = $this.attr('href').substr(1),
            $target = $('a[name="' + href + '"]'),
            $methods = $('a.method.' + href, $apiDocContainer),
            offsetTop = $target.offset().top,
            $container = $target.closest('.api-entry-container');
          if (scrollTop < offsetTop + $container.outerHeight() && scrollTop + windowHeight > offsetTop) {
            $methods.addClass('shown');
          } else {
            $methods.removeClass('shown');
          }
        });
      });
    });
  });
})(jQuery, window.DGS);