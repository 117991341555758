(function ($, DGS) {
  var burgerMenuConfig = {
    expanderSelector: '.header-mobile-top .nav-expander',
    menuItemsClass: 'text-left',
    groupTwoLastLevels: false
  };
  var megaMenuConfig = {
    hover: true
  };
  DGS.OnLoad.getInstance().register(function () {
    var sectionShown,
      $logo = $('#header .image'),
      $menu = $('#header .navigation'),
      $wordmark = $('#header .rich-text'),
      $search = $('#header .search-field-spot'),
      $mainContainer = $('#wrapper > #header'),
      $languageSelector = $('#header .language-selector-spot'),
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: 'header-mobile-top',
              components: [$logo, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>'), $languageSelector]
              // components: [$wordmark, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>'), $languageSelector],
            }, {
              className: 'header-mobile-menu',
              components: [$menu]
            }]
          },
          desktop: {
            containers: [{
              className: 'header-desktop-top',
              containers: [{
                className: 'row-1',
                components: [$logo, $search, $menu, $languageSelector]
                // components: [$wordmark, $search, $menu, $languageSelector],
              }]
            }]
          }
        }
      };

    // BUILD MARKUP
    DGS.Builder.build(headerSettings);
    DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $('#header').addClass('animate');

      // SETUP MEGA MENU
      $('.desktop-section .navigation .component-content > ul').megaMenu(megaMenuConfig);

      // SETUP BURGER MENU
      var backButton = '<div class="nav-back">Back</div>';
      $('.mobile-section .navigation').before(backButton);
      $('.mobile-section .navigation .component-content > ul').burgerMenu(burgerMenuConfig);

      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = 0;
        if (sectionShown === DGS.DESKTOP_LAYOUT) {
          headerHeight = $('#header .desktop-section > .inner-container').outerHeight();
        } else if (sectionShown === DGS.MOBILE_LAYOUT) {
          headerHeight = $('#header .header-mobile-top').outerHeight();
          $('.dgs-burger-menu').css('top', headerHeight);
          $('.nav-back').css('top', headerHeight);
        }
        $('#header').css('margin-bottom', headerHeight);
      });
    });

    // Prevent browser from showing tooltip with title attributes
    var $attr = $('[title]');
    $attr.hover(function () {
      var title = $(this).attr('title');
      $(this).attr('temp-title', title);
      $(this).attr('title', '');
    });
    $attr.click(function () {
      var title = $(this).attr('temp-title');
      $(this).attr('title', title);
    });
    // Prevent browser from showing tooltip with title attributes - end
  });
})(jQuery, window.DGS);