require("./component-data-list-spot-base-custom");
// require("../../../../wdh-feature/wdh-legacy/data-list-spot/js/base/component-data-list-spot.js");
(function ($) {
  var visualRowIndex = -1,
    $prevRow = null;
  window.DGS.DataListSpotConfig.resolveSortForField = function (fieldName, sortType) {
    var sortOrder = sortType == 'ordered-asc' ? ' asc' : ' desc';
    return 'orderby=' + fieldName + sortOrder;
  };
  window.DGS.DataListSpotConfig.decorateRowAtIndex = function (data, index, $row) {
    var oddEven,
      type,
      oddClass = "data-list-spot__row-odd",
      evenClass = "data-list-spot__row-even";
    if (!data.attachedToLineId) {
      oddEven = index % 2 == 0 ? evenClass : oddClass;
    } else {
      if (index == 0) {
        visualRowIndex = -1;
        $prevRow = null;
      }
      if (data.attachedToLineId == 0) {
        visualRowIndex++;
        type = "main-order";
        if ($prevRow) $prevRow.addClass('last-attached-order-line');
      } else {
        type = "attached-order";
      }
      oddEven = visualRowIndex % 2 == 0 ? evenClass : oddClass;
    }
    $row.addClass(oddEven);
    $row.addClass(type);
    $prevRow = $row;
  };

  // Column properties
  window.DGS.DataListSpotConfig.fieldInfo['memberName'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'endUser'
  };
  window.DGS.DataListSpotConfig.fieldInfo['orderDate'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'orderDateUtc'
  };
  window.DGS.DataListSpotConfig.fieldInfo['orderNumber'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'documentNumber'
  };
  window.DGS.DataListSpotConfig.fieldInfo['paNumber'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'externalDocumentNo'
  };
  window.DGS.DataListSpotConfig.fieldInfo['shippedDate'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'shipmentDateUtc'
  };
  window.DGS.DataListSpotConfig.fieldInfo['serialNo'] = {
    IsFilterable: true,
    IsSearchable: true,
    IsSortable: true,
    MappedTo: 'serialNo'
  };
  DGS.Event.getInstance().on(window.DGS.DataListSpotConfig.Event.DATA_LIST_BUILT, function () {
    $('.data-list-spot__row__column.checkbox').each(function () {
      var extendable = false;
      var childSpan = $(this).children('span').text('');
      if ($(this).data('value') == 1) {
        childSpan.append('<input type="checkbox" class="selectable" />');
        extendable = true;
      }
      if (extendable) {
        $(this).closest('.data-list-spot').data('isextendable', extendable);
      }
    });
    $('.data-list-spot__row').each(function () {
      var expireDate = $(this).children('.ExpiresDate').data('value');
      // Get 1 day in milliseconds
      var one_day = 1000 * 60 * 60 * 24;
      var now = new Date();
      var diff = new Date(expireDate).getTime() - now.getTime();
      if (diff <= 14 * one_day) {
        $(this).addClass('expiring');
        $(this).children('.data-list-spot__row__column').each(function () {
          $(this).addClass('expiring');
        });
      }
    });
    var baseServiceURL = "/webservices/Warranties.svc";
    $('.data-list-spot__row__column.printable').each(function () {
      var childSpan = $(this).children('span').text('');
      if ($(this).data('value') == 1) {
        childSpan.append('<img class="printable" src="/design/build/oticon_us/assets/print-icon.png" width="30px"/>');
      }
    });
    $('.data-list-spot__row__column.hascoverage').each(function () {
      var childSpan = $(this).children('span');
      if ($(this).data('value') == 'NO' && childSpan.children().length === 0) {
        childSpan.append('<img class="hascoverage" src="/design/build/oticon_us/assets/badge-no.png" width="20px"/>');
      } else if ($(this).data('value') == 'YES' && childSpan.children().length === 0) {
        childSpan.append('<img class="hascoverage" src="/design/build/oticon_us/assets/badge-yes.png" width="20px"/>');
      }
    });
    $('.data-list-spot').on('click', function (e) {
      if ($(e.target).is('input.selectable')) {
        e.stopImmediatePropagation();
      }
      if ($(e.target).is('img') && $(e.target).hasClass('printable')) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var serviceCard = $(e.target).closest('.data-list-spot__row').children('.serviceCardNo:first').data('value');
        if (serviceCard) {
          var resultUrl = baseServiceURL + "/" + "requestpdf" + "/" + serviceCard;
          var attempt = 10;
          attemptPDF(attempt, resultUrl, serviceCard);
        }
        return false;
      }
    });
    function attemptPDF(attempt, url, serviceCard) {
      if (attempt < 0) return false;
      if (attempt === 0) getPDF(serviceCard);
      $.ajax({
        url: url,
        type: 'GET'
      }).then(function (data, textState, jqXHR) {
        switch (jqXHR.status) {
          case 201:
            getPDF(serviceCard);
            break;
          case 202:
            setTimeout(getPDF(serviceCard), 500);
            break;
          default:
            return false;
            break;
        }
      }).then(function (data, textState, jqXHR) {
        return false;
      });
      return false;
    }
    function getPDF(serviceCard) {
      var resultUrl = "/webservices/Warranties.svc" + "/" + "RequestPDFCertificate" + "/" + serviceCard;
      window.location = resultUrl;
    }
  });
})(jQuery);