// WDH FEATURE B2B
// import WDHFeatureB2BBrowseShopCategoriesSpot from './browse-shop-categories-spot';
// import WDHFeatureB2BCartSpot from './cart-spot';
import WDHFeatureB2BAccountTogglingSpot from './account-toggling-spot';
import WDHFeatureB2BManageCatalogsSpot from './manage-catalogs-spot';
import WDHFeatureB2BProductSearchResultSpot from './product-search-result-spot';
import WDHFeatureB2BProductDetailsSpot from './product-details-spot';
import WDHFeatureB2BCheckoutConfirmationSpot from './checkout-confirmation-spot';
import WDHFeatureB2BClientOrderStep1Spot from './client-order-step1-spot';
import WDHFeatureB2BClientOrderStep3Spot from './client-order-step3-spot';
import WDHFeatureB2BSelectOrderTypeSpot from './select-order-type-spot';
import WDHFeatureB2BShopInitializationSpot from './shop-initialization-spot';
import WDHFeatureB2BCheckoutSpot from './checkout-spot';
import WDHFeatureB2BCheckoutPrintSpot from './checkout-print-spot';
import WDHFeatureB2BCartPrintSpot from './cart-print-spot';
import WDHFeatureB2BRecentlyViewedProductsSpot from './recently-viewed-products-spot';
import WDHFeatureB2BProductSearchSpot from './product-search-spot';
import WDHFeatureB2BLandingPageSpot from './landing-page-spot';
import WDHFeatureB2BClientOrderStep2Spot from './client-order-step2-spot';
import WDHFeatureB2BProductListSpot from './product-list-spot';
import WDHFeatureB2BSubheaderSpot from './subheader-spot';
import WDHFeatureB2BAccountInformationSpot from './account-information-spot';
import WDHFeatureB2BProfileInformationSpot from './profile-information-spot';
import WDHFeatureB2BOrderHistorySpot from './order-history-spot';
import WDHFeatureB2BOrderHistoryTileSpot from './order-history-tile-spot';
import WDHFeatureB2BServiceOrderHistorySpot from './service-order-history-spot';
import WDHFeatureB2BServiceCardsSpot from './service-cards-spot';
import WDHFeatureB2BShopStatusSpot from './shop-status-spot';
import WDHFeatureB2BMyBusinessLandingPageHeaderSpot from './mybusiness-landing-page-header-spot';
import WDHFeatureB2BMyBusinessDashboardSpot from './mybusiness-dashboard-spot';
import WDHFeatureB2BInvoicesSpot from './invoices-spot';
import WDHFeatureB2BMyBusinessMarketingAreaDownloadSpot from './mybusiness-marketing-area-download-spot';
import WDHFeatureB2BMyBusinessMarketingAreaOrderSpot from './mybusiness-marketing-area-order-spot';
import WDHFeatureB2BMyBusinessMarketingAreaLandingPageSpot from './mybusiness-marketing-area-landing-page-spot';
import WDHFeatureB2BMyBusinessRegisterServiceOrderSpot from './mybusiness-register-service-order-spot';
import WDHFeatureB2BMyBusinessServiceOrderPrintSpot from './mybusiness-service-order-print-spot';

// ShopInitializationSpot depends on WDH.Feature.B2B.Authentication\B2BUI\Sublayouts\LoginSpot
new WDHFeatureB2BShopInitializationSpot().run();
// new WDHFeatureB2BBrowseShopCategoriesSpot().run();

// CartSpot initialization has been moved to 'header-shopping-cart-link.js' file.
// new WDHFeatureB2BCartSpot().run();

new WDHFeatureB2BAccountTogglingSpot().run();
new WDHFeatureB2BCheckoutSpot().run();
new WDHFeatureB2BCheckoutPrintSpot().run();
new WDHFeatureB2BCartPrintSpot().run();
new WDHFeatureB2BManageCatalogsSpot().run();
new WDHFeatureB2BProductDetailsSpot().run();
new WDHFeatureB2BProductListSpot().run();
new WDHFeatureB2BProductSearchSpot().run();
new WDHFeatureB2BRecentlyViewedProductsSpot().run();
new WDHFeatureB2BProductSearchResultSpot().run();
new WDHFeatureB2BCheckoutConfirmationSpot().run();
new WDHFeatureB2BClientOrderStep1Spot().run();
new WDHFeatureB2BClientOrderStep2Spot().run();
new WDHFeatureB2BClientOrderStep3Spot().run();
new WDHFeatureB2BSelectOrderTypeSpot().run();
new WDHFeatureB2BSubheaderSpot().run();
new WDHFeatureB2BAccountInformationSpot().run();
new WDHFeatureB2BProfileInformationSpot().run();
new WDHFeatureB2BOrderHistorySpot().run();
new WDHFeatureB2BOrderHistoryTileSpot().run();
new WDHFeatureB2BLandingPageSpot().run();
new WDHFeatureB2BServiceOrderHistorySpot().run();
new WDHFeatureB2BServiceCardsSpot().run();
new WDHFeatureB2BShopStatusSpot().run();
new WDHFeatureB2BMyBusinessLandingPageHeaderSpot().run();
new WDHFeatureB2BMyBusinessDashboardSpot().run();
new WDHFeatureB2BInvoicesSpot().run();
new WDHFeatureB2BMyBusinessMarketingAreaDownloadSpot().run();
new WDHFeatureB2BMyBusinessMarketingAreaOrderSpot().run();
new WDHFeatureB2BMyBusinessMarketingAreaLandingPageSpot().run();
new WDHFeatureB2BMyBusinessRegisterServiceOrderSpot().run();
new WDHFeatureB2BMyBusinessServiceOrderPrintSpot().run();