function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
import { useState } from 'react';
var useValidation = function useValidation() {
  var validations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    errors = _useState2[0],
    setErrors = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    isValid = _useState4[0],
    setIsValid = _useState4[1];
  function validate(data) {
    var keys = Object.keys(validations);
    var newErrors = {};
    var newIsValid = true;
    keys.forEach(function (key) {
      var _validation$required, _validation$pattern, _validation$pattern2, _validation$custom, _validation$custom2;
      var validation = validations[key];
      var value = data[key];
      if ((_validation$required = validation.required) !== null && _validation$required !== void 0 && _validation$required.value && !value) {
        var _validation$required2;
        newErrors[key] = (_validation$required2 = validation.required) === null || _validation$required2 === void 0 ? void 0 : _validation$required2.message;
        newIsValid = false;
      } else if ((_validation$pattern = validation.pattern) !== null && _validation$pattern !== void 0 && _validation$pattern.value && !RegExp((_validation$pattern2 = validation.pattern) === null || _validation$pattern2 === void 0 ? void 0 : _validation$pattern2.value).test(value)) {
        var _validation$pattern3;
        newErrors[key] = (_validation$pattern3 = validation.pattern) === null || _validation$pattern3 === void 0 ? void 0 : _validation$pattern3.message;
        newIsValid = false;
      } else if ((_validation$custom = validation.custom) !== null && _validation$custom !== void 0 && _validation$custom.func && typeof ((_validation$custom2 = validation.custom) === null || _validation$custom2 === void 0 ? void 0 : _validation$custom2.func) === 'function') {
        var _validation$custom3;
        var result = (_validation$custom3 = validation.custom) === null || _validation$custom3 === void 0 ? void 0 : _validation$custom3.func(value);
        if (result && _typeof(result) === 'object') {
          var _result$message, _result$passed;
          newErrors[key] = (_result$message = result === null || result === void 0 ? void 0 : result.message) !== null && _result$message !== void 0 ? _result$message : '';
          newIsValid = (_result$passed = result === null || result === void 0 ? void 0 : result.passed) !== null && _result$passed !== void 0 ? _result$passed : false;
        } else if (!result) {
          var _validation$custom4;
          newErrors[key] = (_validation$custom4 = validation.custom) === null || _validation$custom4 === void 0 ? void 0 : _validation$custom4.message;
          newIsValid = false;
        }
      }
    });
    setIsValid(newIsValid);
    setErrors(newErrors);
  }
  return [validate, errors, isValid];
};
export default useValidation;