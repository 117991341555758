(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    // Grabbing extranet menu and display it within the main menu.
    var $profile = $('<li> </li>');
    $profile.addClass('level1');
    $profile.addClass('tier1');
    $profile.append('<a href="#" class="profile-menu">&nbsp;<span class="icon"> </span>&nbsp;</a>');
    if ($('.extranet-login').length > 0) {
      // assume that if we are on the login screen that the user has logged out.
      // currently no other way to track this.
      window.sessionStorage.removeItem('cid');
      window.sessionStorage.removeItem('accessToken');
    } else {
      var getCustomPropertyUrl = '/webservices/extranet.svc/getcustomproperty/CustomerID';
      var getAccessTokenPropertyUrl = '/webservices/extranet.svc/getcustomproperty/OneIdAccessTokenValue';
      // grab our CustomerID from our webservice            
      if (!window.sessionStorage.getItem('cid')) {
        $.ajax({
          url: getCustomPropertyUrl,
          method: 'get'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 200:
              if (data) {
                window.sessionStorage.setItem('cid', data);
              }
              break;
            case 404:
              console.warn('Warning, could not retrieve Customer ID');
              break;
            default:
              break;
          }
        });
      }
      if (!window.sessionStorage.getItem('accessToken')) {
        $.ajax({
          url: getAccessTokenPropertyUrl,
          method: 'get'
        }).then(function (data, textState, jqXHR) {
          switch (jqXHR.status) {
            case 200:
              if (data) {
                window.sessionStorage.setItem('accessToken', data);
              }
              break;
            case 404:
              console.warn('Warning, could not retrieve Customer ID');
              break;
            default:
              break;
          }
        });
      }
    }
    $profile.append($('.extranet-menu-authorized ul'));
    $('.navigation .component-content > ul').append($profile);

    // hide account update information from user.  We don't want them to edit these fields.
    // Only works on update-account page
    var $updateAccountForm = $('.extranet-update-account');
    if ($updateAccountForm.length) {
      var $desc = $('.description', $updateAccountForm);
      var $title = $('.title', $updateAccountForm);
      var $name = $('.name', $updateAccountForm);
      var $company = $('.company', $updateAccountForm);
      var $address = $('.address', $updateAccountForm);
      var $city = $('.city', $updateAccountForm);
      var $country = $('.country', $updateAccountForm);
      var $email = $('.email', $updateAccountForm);
      var $storeid = $('.storeid', $updateAccountForm);

      // hide the fields we don't want to show to the user.
      $desc.css('display', 'none');
      $title.css('display', 'none');
      $company.css('display', 'none');
      $address.css('display', 'none');
      $city.css('display', 'none');
      $country.css('display', 'none');

      // disable the fields we want to show
      $('input', $name).prop('disabled', true);
      $('input', $email).prop('disabled', true);
      $('input', $storeid).prop('disabled', true);

      // put blank values in empty fields to allow validation to pass.  These values should exist already but just in case.
      var $inputTitle = $('input', $title);
      var $inputName = $('input', $name);
      var $inputCompany = $('input', $company);
      var $inputAddress = $('input', $address);
      var $inputCity = $('input', $city);
      var $inputCountry = $('input', $country);
      $inputTitle.val($inputTitle.val() !== '' ? $inputTitle.val() : 'NA');
      $inputName.val($inputName.val() !== '' ? $inputName.val() : 'NA');
      $inputCompany.val($inputCompany.val() !== '' ? $inputCompany.val() : 'NA');
      $inputAddress.val($inputAddress.val() !== '' ? $inputAddress.val() : 'NA');
      $inputCity.val($inputCity.val() !== '' ? $inputCity.val() : 'NA');
      $inputCountry.val($inputCountry.val() !== '' ? $inputCountry.val() : 'NA');
    }

    // hide account update information from user.  We don't want them to edit these fields.
    // Only works on update-account page
    var $completeAccountForm = $('.extranet-complete-account');
    if ($completeAccountForm.length) {
      var _$desc = $('.description', $completeAccountForm);
      var $passwordHeading = $('.password-heading', $completeAccountForm);
      var _$title = $('.title', $completeAccountForm);
      var _$name = $('.name', $completeAccountForm);
      var _$company = $('.company', $completeAccountForm);
      var _$address = $('.address', $completeAccountForm);
      var _$city = $('.city', $completeAccountForm);
      var _$country = $('.country', $completeAccountForm);
      var _$email = $('.email', $completeAccountForm);
      var _$storeid = $('.storeid', $completeAccountForm);
      var storeNumberLabel = $('.customerID label', $completeAccountForm);

      // customize the customer ID label
      // TODO add this as a dictionary item
      storeNumberLabel.html('Costco Store Number');

      // hide the fields we don't want to show to the user.
      _$desc.css('font-weight', 'bold');
      $passwordHeading.css('font-weight', 'bold');
      $passwordHeading.css('margin-left', 0);
      _$title.css('display', 'none');
      _$company.css('display', 'none');
      _$address.css('display', 'none');
      _$city.css('display', 'none');
      _$country.css('display', 'none');

      // disable the fields we want to show
      $('input', _$name).prop('disabled', true);
      $('input', _$email).prop('disabled', true);
      $('input', _$storeid).prop('disabled', true);

      // put blank values in empty fields to allow validation to pass.  These values should exist already but just in case.
      var _$inputTitle = $('input', _$title);
      var _$inputName = $('input', _$name);
      var _$inputCompany = $('input', _$company);
      var _$inputAddress = $('input', _$address);
      var _$inputCity = $('input', _$city);
      var _$inputCountry = $('input', _$country);
      _$inputTitle.val(_$inputTitle.val() !== '' ? _$inputTitle.val() : 'NA');
      _$inputName.val(_$inputName.val() !== '' ? _$inputName.val() : 'NA');
      _$inputCompany.val(_$inputCompany.val() !== '' ? _$inputCompany.val() : 'NA');
      _$inputAddress.val(_$inputAddress.val() !== '' ? _$inputAddress.val() : 'NA');
      _$inputCity.val(_$inputCity.val() !== '' ? _$inputCity.val() : 'NA');
      _$inputCountry.val(_$inputCountry.val() !== '' ? _$inputCountry.val() : 'NA');
    }

    // hide fields not necessary to have from user.
    // Only works on reset-password page
    var $resetPasswordForm = $('.extranet-reset-password');
    if ($resetPasswordForm.length) {
      var _$desc2 = $('.description', $resetPasswordForm);
      var _$title2 = $('.title', $resetPasswordForm);
      var _$company2 = $('.company', $resetPasswordForm);
      var $company2 = $('.comapny', $resetPasswordForm); // form has typo in it.  
      var _$address2 = $('.address', $resetPasswordForm);
      var _$city2 = $('.city', $resetPasswordForm);
      var _$country2 = $('.country', $resetPasswordForm);
      var _storeNumberLabel = $('.customerid label', $resetPasswordForm);

      // customize the customer ID label
      // TODO add this as a dictionary item
      _storeNumberLabel.html('Costco Store Number');

      // hide the fields we don't want to show to the user.
      _$desc2.css('display', 'none');
      _$title2.css('display', 'none');
      _$company2.css('display', 'none');
      $company2.css('display', 'none');
      _$address2.css('display', 'none');
      _$city2.css('display', 'none');
      _$country2.css('display', 'none');
    }

    // hide fields not necessary to have from user.
    // Only works on update-account page
    var $requestAccountForm = $('.extranet-request-account');
    if ($requestAccountForm.length) {
      var _$desc3 = $('.description', $requestAccountForm);
      var _$title3 = $('.title', $requestAccountForm);
      var _$name2 = $('.name', $requestAccountForm);
      var _$company3 = $('.company', $requestAccountForm);
      var _$address3 = $('.address', $requestAccountForm);
      var _$city3 = $('.city', $requestAccountForm);
      var _$country3 = $('.country', $requestAccountForm);
      var _$email2 = $('.email', $requestAccountForm);
      var _storeNumberLabel2 = $('.customerid label', $requestAccountForm);
      var storeNumberInput = $('.customerid input');
      var storeNumberError = $('.extranet-summary');

      // customize the customer ID label
      // TODO add this as a dictionary item
      _storeNumberLabel2.html('Costco Store Number');

      // hide the fields we don't want to show to the user.
      _$desc3.css('display', 'none');
      _$title3.css('display', 'none');
      _$company3.css('display', 'none');
      _$address3.css('display', 'none');
      _$city3.css('display', 'none');
      _$country3.css('display', 'none');

      // put blank values in empty fields to allow validation to pass.  These values should exist already but just in case.
      var _$inputTitle2 = $('input', _$title3);
      var _$inputCompany2 = $('input', _$company3);
      var _$inputAddress2 = $('input', _$address3);
      var _$inputCity2 = $('input', _$city3);
      var _$inputCountry2 = $('input', _$country3);
      _$inputTitle2.val(_$inputTitle2.val() !== '' ? _$inputTitle2.val() : 'NA');
      _$inputCompany2.val(_$inputCompany2.val() !== '' ? _$inputCompany2.val() : 'NA');
      _$inputAddress2.val(_$inputAddress2.val() !== '' ? _$inputAddress2.val() : 'NA');
      _$inputCity2.val(_$inputCity2.val() !== '' ? _$inputCity2.val() : 'NA');
      _$inputCountry2.val(_$inputCountry2.val() !== '' ? _$inputCountry2.val() : 'NA');
      var $submitButton = $('.reset-button input', $requestAccountForm);
      $submitButton.on('click', function () {
        var $isCanada = $('.costco-ca').length > 0;
        if (storeNumberInput.val() === '') {
          // TODO make this a dictionary item so we can translate it.
          storeNumberError.append('<ul><li>Store Number cannot be empty</li></ul>');
          storeNumberError.css('display', 'block');
          return false;
        }
        if ($isCanada) {
          // pad value with 0's
          storeNumberInput.val(storeNumberInput.val().padStart(4, 0));
        }
        return true;
      });
    }
  });
})(jQuery, window.DGS);