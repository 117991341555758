(function ($, moment) {
  "use strict";

  var customDatePicker = function customDatePicker(options) {
    var $this = $(this),
      tmpFrom,
      tmpTo;
    // add html to page
    $this.wrap('<div class="custom-datepicker"></div>');
    $this.prop('disable', true);
    $this.after('<div class="cd-container"><div class="cd-calendar"></div><div class="cd-range-container"><div class="cd-ranges"></div><div class="cd-date"></div><div class="cd-ctas"></div></div></div>');
    var consts = {
        TO: "to",
        FROM: "from",
        FULL_MONTH: "mo",
        MONTH: "m",
        DAY: "d",
        YEAR: "y"
      },
      options = options || {},
      getOption = function getOption(property, defaultValue) {
        if (options.hasOwnProperty(property)) {
          return options[property];
        }
        return defaultValue;
      },
      getParameterizedExpression = function getParameterizedExpression(expr) {
        var output = {
          value: parseInt(expr),
          type: null
        };
        if (expr.indexOf(consts.FULL_MONTH) > -1) {
          output.type = consts.FULL_MONTH;
        } else if (expr.indexOf(consts.DAY) > -1) {
          output.type = consts.DAY;
        } else if (expr.indexOf(consts.MONTH) > -1) {
          output.type = consts.MONTH;
        } else if (expr.indexOf(consts.YEAR) > -1) {
          output.type = consts.YEAR;
        }
        return output;
      },
      saveDate = function saveDate(type, expr) {
        var d = new Date(),
          pExpr = getParameterizedExpression(expr);
        d.setHours(0, 0, 0, 0);
        if (pExpr.type == consts.FULL_MONTH) {
          if (type == consts.FROM) {
            d.setMonth(d.getMonth() + pExpr.value);
            d.setDate(1);
          } else {
            d.setMonth(d.getMonth() + pExpr.value + Math.abs(pExpr.value));
            d.setDate(1);
            d.setDate(d.getDate() - 1);
          }
        } else if (pExpr.type == consts.DAY && type == consts.FROM) {
          d.setDate(d.getDate() + pExpr.value + 1);
        } else if (pExpr.type == consts.MONTH && type == consts.FROM) {
          d.setMonth(d.getMonth() + pExpr.value);
        } else if (pExpr.type == consts.YEAR && type == consts.FROM) {
          d.setYear(d.getYear() + pExpr.value);
        }
        switch (type) {
          case consts.FROM:
            tmpFrom = d;
            break;
          case consts.TO:
            tmpTo = d;
            break;
        }
      },
      unsetAllRangeButtons = function unsetAllRangeButtons($container) {
        $('.cd-range-button', $container).removeClass('active');
      },
      verifyDateAndHandleResult = function verifyDateAndHandleResult() {
        $('.negative-range.invalid').addClass('hide');
        $('.invalid-range.invalid').addClass('hide');
        if (!verifyDateOrder()) {
          $('.negative-range.invalid').removeClass('hide');
          $('.cd-cta-apply').addClass('disabled');
          return;
        }
        if (!verifyDateLimit()) {
          $('.invalid-range.invalid').removeClass('hide');
          $('.cd-cta-apply').addClass('disabled');
          return;
        }
        $('.cd-cta-apply').removeClass('disabled');
      },
      verifyDateOrder = function verifyDateOrder() {
        return tmpFrom <= tmpTo;
      },
      verifyDateLimit = function verifyDateLimit() {
        if (dateRangeLimit) {
          var pExpr = getParameterizedExpression(dateRangeLimit),
            deltaD = Math.abs(tmpTo - tmpFrom),
            deltaD = deltaD - deltaD % 86400000,
            // Adjust delta to be whole days
            dateRangeLimitInMillis = 0;
          pExpr.value = Math.abs(pExpr.value); // We always want a positive integer
          switch (pExpr.type) {
            case consts.FULL_MONTH:
            case consts.MONTH:
              dateRangeLimitInMillis = pExpr.value * 31 * 24 * 60 * 60 * 1000;
              break;
            case consts.DAY:
              dateRangeLimitInMillis = pExpr.value * 24 * 60 * 60 * 1000;
              break;
            case consts.YEAR:
              dateRangeLimitInMillis = pExpr.value * 365 * 24 * 60 * 60 * 1000;
              break;
          }
          return dateRangeLimitInMillis === 0 || deltaD <= dateRangeLimitInMillis;
        }
      },
      setTmpDateFields = function setTmpDateFields() {
        var format = getOption("dateFormat", "ymd");
        $('.cd-date-container:nth-child(1) .cd-date-value').text(moment(tmpFrom).format(format));
        $('.cd-date-container:nth-child(2) .cd-date-value').text(moment(tmpTo).format(format));
      },
      revertDataValue = function revertDataValue() {
        tmpFrom = $this.data('from');
        tmpTo = $this.data('to');
      },
      saveDataValue = function saveDataValue() {
        var format = getOption("dateFormat", "ymd");
        $this.val(moment(tmpFrom).format(format) + '  -  ' + moment(tmpTo).format(format));
        $this.data('from', tmpFrom);
        $this.data('to', tmpTo);
        $this.change();
      },
      populateDateFromExpression = function populateDateFromExpression(expr) {
        saveDate(consts.FROM, expr);
        saveDate(consts.TO, expr);
        verifyDateAndHandleResult();
        setTmpDateFields();
      },
      populateDateFromInitialValues = function populateDateFromInitialValues(from, to) {
        tmpFrom = moment(from);
        tmpTo = moment(to);
        verifyDateAndHandleResult();
        setTmpDateFields();
      },
      registerPageClick = function registerPageClick() {
        hideDatePicker();
      },
      hideDatePicker = function hideDatePicker() {
        $cdContainer.hide();
        window.DGS.PageClick.getInstance().remove(registerPageClick);
      },
      shortcuts = getOption('shortcuts', []),
      expr = getOption('expr', "-1m"),
      initialFrom = getOption('initialFrom', null),
      initialTo = getOption('initialTo', null),
      dateRangeLimit = getOption('dateRangeLimit', null),
      fromDateLabel = getOption('fromDateLabel', 'From'),
      toDateLabel = getOption('toDateLabel', 'To'),
      applylabel = getOption('applyLabel', 'Apply'),
      cancelLabel = getOption('cancelLabel', 'Cancel'),
      resetLabel = getOption('resetLabel', 'Reset'),
      negativeRangeLabel = getOption('negativeRangeLabel', "Invalid date range"),
      invalidRangeLabel = getOption('invalidRangeLabel', "Invalid date range"),
      $container = $this.parent(),
      $cdContainer = $this.next(),
      $cdRange = $('.cd-ranges', $cdContainer),
      $cdDate = $('.cd-date', $cdContainer),
      $cdCtas = $('.cd-ctas', $cdContainer),
      $cdCalendar = $('.cd-calendar', $cdContainer);

    // open first dialog and preset date to one of the three options
    hideDatePicker();
    shortcuts.forEach(function (val) {
      var label = val.label,
        expr = val.expr;
      $cdRange.append('<a class="cd-range-button" data-expr="' + expr + '">' + label + '</a>');
    });
    $cdDate.append('<div class="cd-date-container"><div class="cd-date-label">' + fromDateLabel + '</div><div class="cd-date-value" data-type="' + consts.FROM + '"></div></div>');
    $cdDate.append('<div class="cd-date-container"><div class="cd-date-label">' + toDateLabel + '</div><div class="cd-date-value" data-type="' + consts.TO + '"></div></div>');
    $cdDate.append('<div class="clearfix"></div>');
    $cdCtas.append('<div class="cd-ctas-container"><div class="negative-range invalid hide">' + negativeRangeLabel + '</div><div class="invalid-range invalid hide">' + invalidRangeLabel + '</div><div class="cd-cta-apply">' + applylabel + '</div><div class="cd-cta-cancel"></div><div class="cd-cta-reset"> ' + resetLabel + '</div></div> </div>');
    $this.click(function () {
      $cdContainer.toggle();
      $this.blur();
      if ($cdContainer.is(':visible')) window.DGS.PageClick.getInstance().register(registerPageClick, $cdContainer);
    });
    $('.cd-cta-cancel', $container).click(function () {
      hideDatePicker();
      revertDataValue();
      verifyDateAndHandleResult();
      setTmpDateFields();
      unsetAllRangeButtons($container);
    });
    $('.cd-cta-apply', $container).click(function () {
      if ($(this).hasClass('disabled')) return;
      hideDatePicker();
      saveDataValue();
      unsetAllRangeButtons($container);
    });
    $('.cd-cta-reset', $container).click(function () {
      $(this).parents().find('.custom-datepicker').removeClass('searchThroughDate');
      revertDataValue();
      verifyDateAndHandleResult();
      setTmpDateFields();
      unsetAllRangeButtons($container);
    });
    $('.cd-date-value', $container).click(function () {
      // open date picker
      var type = $(this).attr('data-type');
      $cdCalendar.datepicker("destroy");
      $cdCalendar.datepicker({
        onSelect: function onSelect(selectedDate) {
          $(this).parents().find('.custom-datepicker').addClass('searchThroughDate');
          var millis = Date.parse(selectedDate),
            d = new Date();
          d.setTime(millis);
          switch (type) {
            case consts.TO:
              tmpTo = d;
              break;
            case consts.FROM:
              tmpFrom = d;
              break;
          }
          verifyDateAndHandleResult();
          setTmpDateFields();
          $cdCalendar.hide();
          $cdContainer.removeClass('hasDatepicker');
          unsetAllRangeButtons($container);
        }
      });
      $cdCalendar.datepicker("setDate", type == consts.FROM ? tmpFrom : tmpTo);
      $cdCalendar.show();
      $cdContainer.addClass('hasDatepicker');
    });
    $('.cd-range-button', $container).click(function () {
      $(this).parents().find('.custom-datepicker').addClass('searchThroughDate');
      var $this = $(this),
        expr = $this.attr('data-expr');
      populateDateFromExpression(expr);
      unsetAllRangeButtons($container);
      $this.addClass('active');
    });
    if (initialFrom !== null && initialTo !== null) {
      populateDateFromInitialValues(initialFrom, initialTo);
    } else {
      populateDateFromExpression(expr);
    }
    saveDataValue();
  };
  $.fn.extend({
    customDatePicker: customDatePicker,
    customDatePickerFrom: function customDatePickerFrom() {
      return $(this).data('from');
    },
    customDatePickerTo: function customDatePickerTo() {
      return $(this).data('to');
    },
    customDatePickerClear: function customDatePickerClear() {
      $(this).data('to', null);
      $(this).data('from', null);
    },
    customDatePickerOnChange: function customDatePickerOnChange() {
      return 'customDatePickerOnChangeEvent';
    }
  });
})(jQuery, window.moment);