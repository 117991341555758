require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import { TweenMax } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/burgermenu');
require('jquery-bridget');
require('masonry-layout');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('iframe-resizer');
require('@demant/legacy/googlemaps');
window.moment = require("moment");

// FEATURE
require('./feature/component-center-list-spot.js');
require('./feature/component-center-locator.js');
require('./feature/component-center-spot.js');
require('./feature/component-data-hierarchy.js');
require('./feature/component-data-list-spot');
require('./feature/component-video-spot.js');
require('./feature/component-cookies.js');
require('./feature/component-form.js');
require('./feature/component-iframe.js');
require('./feature/component-intro-banner.js');
require('./feature/component-language-selector-spot.js');
require('./feature/component-search.js');
require('./feature/component-submenu-point-spot.js');
require('./feature/component-two-section-grid.js');
require('./feature/component-data-hierarchy-api-documentation.js');
require('./feature/text-image-spot');
require('./feature/wdh-image-spot');
require('./feature/wdh-rich-text');
require('./feature/wdh-tabbed-data-list');
require('./feature/component-data-hierarchy-data-list-search');

// PROJECT
require('../../corporate-template/js/project/main.js');
// require("../../corporate-template/js/project/header.js");
require('./project/extranet');
require('./project/custom-header.js');
require('./project/custom-datepicker');
require('./project/main');
require('./../../../wdh-feature/wdh-feature-b2b/src/js/b2b-shop');
require('@demant/wdh-gdpr/lib/default.implementation.js');

// print shipping label
require('./project/print-shipping-label/print-shipping-label-spot-sbo.js');
require('./project/print-shipping-label/sbo-print-hazmat-label.js');
require('./project/print-shipping-label/sbo-address-info.js');
require('./project/print-shipping-label/sbo-package-content.js');
require('./project/print-shipping-label/sbo-package-options.js');
require('./project/print-shipping-label/sbo-print-modal-dialog.js');
require('./project/print-shipping-label/sbo-print-shipping-label.js');